import { PhotoCamera } from '@mui/icons-material';
import { Box, Button, Input, Modal, Typography } from '@mui/joy';
import { Avatar, FormControl, Grid, MenuItem, Select, Snackbar } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import { useNavigate } from 'react-router-dom';
import Loading from '../shared/Loading';

function Profile() {
    const { t, i18n } = useTranslation(); // Initialize the translation function

    const imageFileName = "Variant81";

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [language, setLanguage] = useState('eng');
    const [user, setUser] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showProfilePictureModal, setShowProfilePictureModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(imageFileName);
    const [tempSelectedImage, setTempSelectedImage] = useState(selectedImage); // Temporary selection
    const [currentPassword, setCurrentPassword] = useState('');
    const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleOpenModal = () => setChangePasswordModalOpen(true);
    const handleCloseModal = () => {
        setChangePasswordModalOpen(false);
        setError('');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    const handleLanguageChange = async (event) => {
        setIsLoading(true);
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage); // Update the language in the component state immediately for UI feedback
        i18n.changeLanguage(selectedLanguage); // Change language

        try {
            // Send an Axios request to update the language preference on the server
            const response = await axios.put('/api/user/language',
                { language: selectedLanguage }, // Pass the selected language in the request body
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (response.status === 200) {
                // Successfully updated the language preference
                setSnackbarMessage(t('snackbar.languageUpdated')); // Use translation
                setSnackbarOpen(true);
            } else {
                // Handle unexpected response
                setSnackbarMessage(t('snackbar.languageUpdateFailed')); // Use translation
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error updating language preference:', error);
            setSnackbarMessage(t('snackbar.languageUpdateError')); // Use translation
            setSnackbarOpen(true);

        } finally {
            setIsLoading(false); // Ensure loading state is turned off
        }
    };

    const handlePasswordChange = async () => {

        setIsLoading(true);

        if (newPassword !== confirmPassword) {
            setError(t('error.passwordMismatch')); // Use translation
            return;
        }
        if (!newPassword || !currentPassword) {
            setError(t('error.fillAllFields')); // Use translation
            return;
        }

        // Clear error if valid
        setError('');

        // Implement the password change logic here, e.g., API call
        try {

            const requestBody = {
                oldPassword: currentPassword,
                newPassword
            }
            const response = await axios.put('/api/auth/reset-password-direct', requestBody, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setSnackbarMessage(t('snackbar.passwordChanged')); // Use translation
                setSnackbarOpen(true);
            } else {
                // Handle unexpected response
                setSnackbarMessage(t('snackbar.passwordChangeFailed')); // Use translation
                setSnackbarOpen(true);
            }

        } catch (error) {
            console.error('Error updating profile picture:', error);
            var errorMessage = error.response?.data?.message;
            setSnackbarMessage(errorMessage ? errorMessage : t('snackbar.passwordChangeFailed')); // Use translation
            setSnackbarOpen(true);
        }

        // Close modal after successful password change
        setIsLoading(false);
        handleCloseModal();
    };

    const handleAvatarClick = (image) => {
        setTempSelectedImage(image); // Set temporary selection
    };

    const handleConfirmProfilePictureChange = async () => {
        setSelectedImage(tempSelectedImage); // Update actual selection

        try {
            const requestBody = {
                profile_picture_id: tempSelectedImage,
            };

            // Make the Axios call to update the profile picture
            const response = await axios.put('/api/user/profile-pic', requestBody, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                // Successfully updated the profile picture

                setSnackbarMessage(t('snackbar.profilePictureChanged')); // Use translation
                setSnackbarOpen(true);
            } else {
                // Handle unexpected response
                setSnackbarMessage(t('snackbar.profilePictureChangeError')); // Use translation
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error updating profile picture:', error);
            setSnackbarMessage('Error changing profile picture. Please try again.');
            setSnackbarOpen(true);
        }

        setShowProfilePictureModal(false);
        setSnackbarMessage('Profile picture changed successfully!');
        setSnackbarOpen(true);
    };

    const handleOpenProfilePictureModal = () => {
        setShowProfilePictureModal(true);
    };

    const handleCloseProfilePictureModal = () => {
        setShowProfilePictureModal(false);
    };

    const handleLogout = () => {
        setIsLoading(true);

        setTimeout(() => {
            window.localStorage.removeItem('token');
            navigate('/');
            setSnackbarMessage(t('snackbar.loggedOut')); // Use translation
            setSnackbarOpen(true);
        }, 500);

    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const fetchUser = axios.get('/api/user', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        Promise.all([fetchUser])
            .then(([userResponse]) => {
                // User
                setUser(userResponse.data);
                setSelectedImage(userResponse.data.profile_picture_id);
                setTempSelectedImage(userResponse.data.profile_picture_id);

                setTimeout(() => {
                    setIsLoading(false);
                }, 500)

            }).catch((error) => {
                console.error('Error fetching data:', error);
                navigate("/");
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Box sx={{ padding: '20px', margin: 'auto' }}>

            {/* Change Profile Picture */}
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
                <Avatar
                    sx={{ width: 100, height: 100, margin: 'auto' }}
                    src={require(`../assets/images/avatar/${selectedImage}.png`)}
                />
                <Button
                    variant="outlined"
                    component="label"
                    startIcon={<PhotoCamera />}
                    onClick={handleOpenProfilePictureModal}
                    sx={{
                        marginTop: 2,
                        color: '#6528cf',
                        borderColor: '#6528cf',
                        '&:hover': {
                            borderColor: '#57bdff',
                            color: '#57bdff'
                        }
                    }}
                >
                    {t('profile.changePicture')} {/* Use translation */}
                </Button>
            </Box>

            {/* Personal Information */}
            <Typography level="h4" gutterBottom>{t('profile.personalInformation')}</Typography>
            <Typography variant="body1"><strong>{t('profile.name')}:</strong> {user.first_name} {user.last_name}</Typography>
            <Typography variant="body1"><strong>{t('profile.email')}:</strong> {user.email}</Typography>
            <Typography variant="body1" ><strong>{t('profile.lastLogin')}:</strong> {moment(user.last_login_datetime).format('DD/MM/YYYY HH:mm:ss')}</Typography>
            <Typography variant="body1" gutterBottom><strong>{t('profile.loginIP')}:</strong> {user.last_login_ip}</Typography>

            {/* Change Language */}
            <FormControl fullWidth sx={{ marginTop: 3, marginBottom: 3 }}>
                <Select
                    value={language}
                    onChange={handleLanguageChange}
                >
                    <MenuItem value="eng">{t('languages.english')}</MenuItem>
                    <MenuItem value="chi">{t('languages.chinese')}</MenuItem>
                    <MenuItem value="ind">{t('languages.indonesian')}</MenuItem>
                    <MenuItem value="kor">{t('languages.korean')}</MenuItem>
                    <MenuItem value="hin">{t('languages.hindi')}</MenuItem>
                    <MenuItem value="jpn">{t('languages.japanese')}</MenuItem>
                    <MenuItem value="th">{t('languages.thai')}</MenuItem>
                </Select>
            </FormControl>

            {/* Change Password */}
            <Button
                variant="contained"
                fullWidth
                sx={{
                    marginBottom: 2,
                    background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    color: 'white',
                    '&:hover': {
                        opacity: 0.9,
                        background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    }
                }}
                onClick={handleOpenModal}
            >
                {t('profile.changePassword')} {/* Use translation */}
            </Button>

            {/* View Terms & Conditions */}
            <Button
                variant="outlined"
                fullWidth
                href="/terms-and-conditions"
                onClick={() => { window.location.href = "/terms-and-conditions" }}
                sx={{
                    marginBottom: 2,
                    color: '#6528cf',
                    borderColor: '#6528cf',
                    '&:hover': {
                        color: '#57bdff',
                        borderColor: '#57bdff'
                    }
                }}
            >
                {t('profile.viewTerms')} {/* Use translation */}
            </Button>

            {/* Logout Button */}
            <Button
                variant="contained"
                fullWidth
                sx={{
                    background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    color: 'white',
                    '&:hover': {
                        opacity: 0.9,
                        background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    },
                    marginTop: 2
                }}
                onClick={handleLogout}
            >
                {t('profile.logout')} {/* Use translation */}

            </Button>

            {/* Change Password Dialog */}
            <Modal open={isChangePasswordModalOpen} onClose={handleCloseModal} aria-labelledby="change-password-modal">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '100vh',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            padding: '20px',
                            borderRadius: '8px',
                            width: '90%',
                            maxWidth: '400px',
                            textAlign: 'center',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography id="change-password-modal" level="h5" fontWeight="bold" mb={2}>
                            {t('profile.changePassword')} {/* Use translation */}
                        </Typography>

                        <Input
                            placeholder={t('profile.currentPassword')}
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            fullWidth
                            sx={{ mb: 2 }}
                        />

                        <Input
                            placeholder={t('profile.newPassword')}
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            fullWidth
                            sx={{ mb: 2 }}
                        />

                        <Input
                            placeholder={t('profile.confirmPassword')}
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            fullWidth
                            sx={{ mb: 2 }}
                        />

                        {error && (
                            <Typography color="danger" fontSize="sm" mb={2}>
                                {error}
                            </Typography>
                        )}

                        <Button
                            onClick={handlePasswordChange}
                            variant="solid"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            {t('profile.confirmChange')}
                        </Button>
                        <Button
                            onClick={handleCloseModal}
                            variant="outlined"
                            color="neutral"
                            fullWidth
                            sx={{ mt: 1 }}
                        >
                            {t('profile.cancel')}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Profile Picture Selection Modal */}
            <Modal open={showProfilePictureModal} onClose={handleCloseProfilePictureModal}>
                <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    width: '80%',
                    maxWidth: '600px',
                    margin: 'auto',
                    marginTop: '10%',
                    textAlign: 'center',
                    maxHeight: '80vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {/* Header and scrollable content */}
                    <Typography variant="h6" gutterBottom> {t('profile.selectProfilePicture')} </Typography>

                    <Box sx={{
                        overflowY: 'auto',
                        maxHeight: '60vh', // Adjust based on remaining height for buttons
                        padding: '10px 0'
                    }}>
                        <Grid container spacing={2}>
                            {Array.from({ length: 78 }, (_, i) => i + 79).map((number) => (
                                <Grid item xs={3} key={number}>
                                    <Avatar
                                        src={require(`../assets/images/avatar/Variant${number}.png`)}
                                        sx={{
                                            width: 80,
                                            height: 80,
                                            cursor: 'pointer',
                                            border: tempSelectedImage === `Variant${number}` ? '2px solid #6528cf' : 'none'
                                        }}
                                        onClick={() => handleAvatarClick(`Variant${number}`)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {/* Fixed footer with buttons */}
                    <Box sx={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px'
                    }}>
                        <Button onClick={handleCloseProfilePictureModal} variant="outlined">{t('profile.cancel')}</Button>
                        <Button onClick={handleConfirmProfilePictureChange} variant="contained">{t('profile.confirmChange')}</Button>
                    </Box>
                </Box>
            </Modal>

            {/* Snackbar for error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
}

export default Profile;