import { Box, Button, Card, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Option, Typography } from '@mui/joy';
import { Snackbar } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from '../shared/Loading';

function InvestRegisterPoints() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(0); // Minimum amount is $100
  const [investWalletAmount, setInvestWalletAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('register');
  const [walletAddress, setWalletAddress] = useState('');
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [user, setUser] = useState(null);
  const [cashWallet, setCashWallet] = useState(null);
  const [interestWallet, setInterestWallet] = useState(null);
  const [investWallet, setInvestWallet] = useState(null);
  const [registerWallet, setRegisterWallet] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate("/");
    }

    const fetchUser = axios.get('/api/user', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });

    const fetchUserWallets = axios.get('/api/user/wallets', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });

    Promise.all([fetchUser, fetchUserWallets])
      .then(([userResponse, walletsResponse]) => {
        setUser(userResponse.data);

        walletsResponse.data.map(wallet => {
          if (wallet['wallet_type'] === "Cash Wallet") {
            setCashWallet(wallet);
          } else if (wallet['wallet_type'] === "Interest Wallet") {
            setInterestWallet(wallet);
          } else if (wallet['wallet_type'] === "Invest Wallet") {
            setInvestWallet(wallet);
          } else if (wallet['wallet_type'] === "Register Wallet") {
            setRegisterWallet(wallet);
          }
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        navigate("/");
        setIsLoading(false);
      });
  }, [navigate]);

  const handleWalletAddressChange = (e) => {
    setWalletAddress(e.target.value);
  };

  const handlePaymentMethodChange = (newValue) => {
    setPaymentMethod(newValue);
    console.log(newValue);
    if (newValue === 'cash') {
      navigate('/invest'); // Navigate on change
    }
  };

  const handleInvest = async () => {
    if (totalAmount >= 100 && amount <= registerWallet['balance']) {
      setError(null);
      setIsLoading(true);

      const requestBody = {
        registerWalletAmount: amount,
      };

      try {
        const response = await axios.post('/api/user/invest-register',
          requestBody,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
        );

        if (response.status === 200) {
          navigate('/success', { state: { message: t('invest.successMessage') } });
        } else {
          setSnackbarMessage('Request failed.');
          setSnackbarOpen(true);
        }

        setIsLoading(false);
      } catch (error) {
        setSnackbarMessage(error.response?.data.message || t('invest.errorMessage'));
        setSnackbarOpen(true);
        setIsLoading(false);
      }
    } else {
      setError(t('invest.invalidInputError'));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="withdraw-page">
      <Card variant="outlined" className="withdraw-card">
        {/* Back Button */}
        <Box display="flex" justifyContent="start" mt={3} ml={2}>
          <Button
            variant="solid"
            onClick={() => navigate(-1)}
            sx={{
              background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
              color: '#ffffff',
              '&:hover': {
                background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
                opacity: 0.9,
              },
            }}
          >
            {t('group.back')}
          </Button>
        </Box>

        <Typography level="h4" className="withdraw-title">{t('invest.title')}</Typography>
        <Typography level="body1" className="withdraw-description">
          {t('invest.description2')}
        </Typography>

        {/* Payment Method Select */}
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>{t('invest.walletType')}</FormLabel>
          <Select
            value={paymentMethod}
            onChange={(event, newValue) => handlePaymentMethodChange(newValue)} // Access newValue directly
          >
            <Option value="cash">{t('wallet.cash')} & {t('wallet.invest')}</Option>
            <Option value="register">{t('wallet.register')}</Option>
          </Select>
        </FormControl>

        {/* Amount Input */}
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>{t('invest.amountLabel2')}</FormLabel>
          <Input
            value={amount}
            onChange={(e) => {
              let inputAmount = e.target.value;
              if (inputAmount.includes('.')) {
                const [integerPart, decimalPart] = inputAmount.split('.');
                if (decimalPart.length > 2) {
                  inputAmount = `${integerPart}.${decimalPart.slice(0, 2)}`;
                }
              }
              const amountFloat = parseFloat(inputAmount) || 0;
              if (amountFloat >= 0 && amountFloat <= registerWallet['balance']) {
                setAmount(parseFloat(inputAmount));
                setTotalAmount((parseFloat(inputAmount) || 0));
              }
            }}
            type="number"
            placeholder={t('invest.amountLabel')}
            min="100"
            max={registerWallet['balance']}
          />
          {error && <Typography color="danger" sx={{ mt: 1 }}>{error}</Typography>}
        </FormControl>

        <Typography style={{ textAlign: 'left', fontSize: '0.8rem', color: 'grey' }}>
          {t('invest.maxCashAmount', { balance: registerWallet['balance'] })}
        </Typography>

        <Typography level='h3' style={{ textAlign: 'right' }}>
          {t('invest.totalAmount', { amount: totalAmount })}
        </Typography>

        <Typography style={{ textAlign: 'right', fontSize: '0.8rem', color: 'grey' }}>
          {t('invest.investMessage')}
        </Typography>

        {/* Confirm Button */}
        <Button
          variant="solid"
          color="primary"
          onClick={handleInvest}
          className="withdraw-button"
          disabled={totalAmount < 100 || amount > registerWallet['balance']}
        >
          {t('invest.confirm')}
        </Button>
      </Card>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
}

export default InvestRegisterPoints;