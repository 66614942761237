import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, TextField, Typography, Snackbar, Link, FormControl, Select, MenuItem, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { ArrowBackIos } from '@mui/icons-material';
import loginBg from '../assets/images/login/loginBg.jpg';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';

const OuterBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${loginBg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  margin: 'auto',
  padding: '25px',
  width: '100wh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '8px',
}));

function ForgotPassword() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value); 
  };

  const navigate = useNavigate(); 

  const goBack = () => {
    navigate(-1); 
  };

  const [username, setUsername] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); 
  const [step, setStep] = useState(1); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [timer, setTimer] = useState(60);
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    let countdown;
    if (timer > 0 && !canResendOtp) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResendOtp(true);
    }
    return () => clearInterval(countdown);
  }, [timer, canResendOtp]);

  const handleSendOtp = async () => {
    if (!username) {
      setSnackbarMessage(t('forgotPassword.snackbar.pleaseEnterUsername'));
      setSnackbarOpen(true);
      return;
    }

    setLoading(true); 
    try {
      await axios.post('/api/auth/otp', { username });
      setSnackbarMessage(t('forgotPassword.snackbar.otpSent'));
      setStep(2);
      setCanResendOtp(false);
      setTimer(60);
    } catch (error) {
      console.error('Error sending OTP:', error.response?.data || error.message);
      setSnackbarMessage(error.response?.data.message || t('forgotPassword.snackbar.failedToSendOtp'));
      setSnackbarOpen(true);
    } finally {
      setLoading(false); 
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp || !newPassword || !confirmPassword) {
      setSnackbarMessage(t('forgotPassword.snackbar.enterCredentials'));
      setSnackbarOpen(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setSnackbarMessage(t('forgotPassword.snackbar.passwordsDoNotMatch'));
      setSnackbarOpen(true);
      return;
    }

    setLoading(true); 
    try {
      await axios.post('/api/auth/reset-password', { username, otp, newPassword });
      setSnackbarMessage(t('forgotPassword.snackbar.passwordResetSuccess'));
      setSnackbarOpen(true);
      navigate('/login');
    } catch (error) {
      console.error('Error verifying OTP:', error.response?.data || error.message);
      setSnackbarMessage(error.response?.data.message || t('forgotPassword.snackbar.failedToResetPassword'));
      setSnackbarOpen(true);
    } finally {
      setLoading(false); 
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleResendOtp = () => {
    handleSendOtp();
  };

  return (
    <OuterBox>
      <StyledBox>
        <Grid container justifyContent={"space-between"} alignItems='center' sx={{ marginBottom: 30 }}>
          <Grid xs={5}>
            <Button startIcon={<ArrowBackIos />} onClick={goBack} />
          </Grid>
          <Grid xs={5}>
            <FormControl>
                <Select
                  label={t('language')}
                  defaultValue="eng" 
                  onChange={handleLanguageChange} 
                  sx={{ color: 'primary.main' }}
                >
                  <MenuItem value="eng">{t('languages.english')}</MenuItem>
                  <MenuItem value="chi">{t('languages.chinese')}</MenuItem>
                  <MenuItem value="ind">{t('languages.indonesian')}</MenuItem>
                  <MenuItem value="kor">{t('languages.korean')}</MenuItem>
                  <MenuItem value="hin">{t('languages.hindi')}</MenuItem>
                  <MenuItem value="jpn">{t('languages.japanese')}</MenuItem>
                  <MenuItem value="th">{t('languages.thai')}</MenuItem>
                </Select>
              </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h6">{t('forgotPassword.title')}</Typography>
        {step === 1 ? (
          <>
            <TextField
              label={t('forgotPassword.username')}
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button
              sx={{ 
                background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                color: 'white',
                '&:hover': {
                    opacity: 0.9,
                    background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                },
              }}   
              variant="contained" onClick={handleSendOtp} fullWidth disabled={loading}>
              {loading ? <CircularProgress size={24} /> : t('forgotPassword.sendOtpButton')}
            </Button>
          </>
        ) : (
          <>
            <TextField
              label={t('forgotPassword.otp')}
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label={t('forgotPassword.newPassword')}
              variant="outlined"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label={t('forgotPassword.confirmPassword')}
              variant="outlined"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button 
              sx={{ 
                background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                color: 'white',
                '&:hover': {
                    opacity: 0.9,
                    background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                },
              }}  
              variant="contained" onClick={handleVerifyOtp} fullWidth disabled={loading}>
              {loading ? <CircularProgress size={24} /> : t('forgotPassword.resetPasswordButton')}
            </Button>
            {canResendOtp && (
              <Button
                sx={{ 
                    background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    color: 'white',
                    '&:hover': {
                        opacity: 0.9,
                        background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    },
                    marginTop: 2 
                }}  
                variant="outlined" onClick={handleResendOtp} fullWidth disabled={loading}>
                {t('forgotPassword.resendOtpButton')}
              </Button>
            )}
            {!canResendOtp && (
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                {t('forgotPassword.countdownMessage', { timer })}
              </Typography>
            )}
          </>
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </StyledBox>
    </OuterBox>
  );
}

export default ForgotPassword;
