import React, { useState, useEffect } from 'react';
import logo from '../../../assets/images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';

// Font Awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faHome } from '@fortawesome/free-solid-svg-icons';

function AdminHeader() {
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const handleScroll = () => {
        if (window.scrollY > 0) {
        setIsScrolled(true);
        } else {
        setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <header className={`app-header ${isScrolled ? 'scrolled' : ''}`}>
        {/* Sidebar Toggle Button */}
        <button className="header-btn toggle-btn" onClick={() => navigate('/admin/user-management')} aria-label="Open Sidebar">
          <FontAwesomeIcon icon={faHome} size="xs" />
        </button>

        {/* Logo in the Center */}
        <div className="header-logo" onClick={() => navigate('/admin/user-management')}>
            <img src={logo} alt="Logo" className="logo-img" />
        </div>

    </header>
  );
}

export default AdminHeader;