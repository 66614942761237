// SuccessPage.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button, Box } from '@mui/joy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const Success = () => {
  const { t } = useTranslation(); // Initialize the translation function
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message || t('messages.successOperation');

  const handleBackHome = () => {
    navigate('/home');
  };

  return (
    <Box sx={styles.container}>
      {/* Success Message at the Top */}
      <Box sx={styles.messageContainer}>
        <CheckCircleOutlineIcon sx={styles.icon} />
        <Typography level="h2" sx={styles.title}>{t('messages.success')}</Typography>
        <Typography level="body1" sx={styles.message}>{message}</Typography>
      </Box>
      
      {/* Bottom Button */}
      <Box sx={styles.buttonContainer}>
        <Button variant="solid" color="primary" onClick={handleBackHome}>{t('messages.toHome')}</Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '70vh',
    padding: '20px',
  },
  messageContainer: {
    textAlign: 'center',
    paddingTop: '30px',
    paddingBottom: '20px',
  },
  icon: {
    fontSize: '80px',
    color: '#4caf50', // Green color for success
    marginBottom: '10px',
    animation: 'bounce 1s ease', // Apply bounce animation
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  message: {
    color: '#555',
    maxWidth: '500px',
    margin: '0 auto',
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'center',
    padding: '20px',
  },
  // CSS keyframes for bounce animation
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': { transform: 'translateY(0)' },
    '40%': { transform: 'translateY(-20px)' },
    '60%': { transform: 'translateY(-10px)' },
  },
};

export default Success;