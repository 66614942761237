// Withdraw.js
import { Box, Button, Card, FormControl, FormLabel, Input, Radio, RadioGroup, Typography } from '@mui/joy';
import { Snackbar } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../shared/Loading';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode to decode the token

function Withdraw() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(10); // Minimum amount is $10
  const [paymentMethod, setPaymentMethod] = useState('TRC-20');
  const [walletAddress, setWalletAddress] = useState('');
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [user, setUser] = useState(null);
  const [cashWallet, setCashWallet] = useState(null);
  const [interestWallet, setInterestWallet] = useState(null);
  const [investWallet, setInvestWallet] = useState(null);
  const [directReferralWallet, setDirectReferralWallet] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const walletType = queryParams.get('wallet');

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);

        if (decodedToken.masterPassword) {
          navigate("/");
        }

        const fetchUser = axios.get('/api/user', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserWallets = axios.get('/api/user/wallets', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        Promise.all([fetchUser, fetchUserWallets])
        .then(([userResponse, walletsResponse]) => {
            // User
            setUser(userResponse.data);

            // Wallets
            walletsResponse.data.map(wallet => {
                if (wallet['wallet_type'] === "Cash Wallet") {
                    setCashWallet(wallet);
                } else if (wallet['wallet_type'] === "Interest Wallet") {
                    setInterestWallet(wallet);
                } else if (wallet['wallet_type'] === "Invest Wallet") {
                    setInvestWallet(wallet);
                } else if (wallet['wallet_type'] === "Direct Referral Wallet") {
                    setDirectReferralWallet(wallet);
                }
            })

            setTimeout(() => {
                setIsLoading(false);
            }, 500)
            
        }).catch((error) => {
            console.error('Error fetching data:', error);
            navigate("/");
            setIsLoading(false);
        });
    }, []);

  const handleWalletAddressChange = (e) => {
    setWalletAddress(e.target.value);
  };

  const handleWithdraw = async () => {
    if (
      amount >= 10 &&
      (
          (walletType === 'Cash Wallet' && amount <= cashWallet['balance']) ||
          (walletType === 'Direct Referral Wallet' && amount <= directReferralWallet['balance'])
      ) &&
      walletAddress
  ) { // Ensure minimum amount and wallet address are provided
      setError(null);
      setIsLoading(true);

      const requestBody = {
        amount,
        paymentMethod,
        walletAddress,
        walletType
      };
      
      try {
        const response = await axios.post('/api/user/withdrawal/request', 
          requestBody,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
        );
  
        if (response.status === 200) {
          navigate('/success', { state: { message: t('withdraw.successMessage') } }); // Translation
        } else {
          setSnackbarMessage('Request failed.');
          setSnackbarOpen(true);
        }

        setIsLoading(false);

      } catch (error) {
        setSnackbarMessage(error.response?.data.message || t('withdraw.errorMessage')); // Translation
        setSnackbarOpen(true);
        setIsLoading(false);
      }
    } else {
      setError(t('withdraw.invalidInputError')); // Translation
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="withdraw-page">

      <Card variant="outlined" className="withdraw-card">

        {/* Back Button */}
        <Box display="flex" justifyContent="start" mt={3} ml={2}>
          <Button
            variant="solid"
            onClick={() => navigate(-1)} // Go back to the previous page
            sx={{
              background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
              color: '#ffffff',
              '&:hover': {
                background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
                opacity: 0.9,
              },
            }}
          >
            {t('group.back')}
          </Button>
        </Box>

        <Typography level="h4" className="withdraw-title">{t('withdraw.title')}</Typography> {/* Translation */}
        <Typography level="body1" className="withdraw-description">
          {t('withdraw.description')} {/* Translation */}
        </Typography>
        
        {/* Amount Input */}
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>{t('withdraw.amountLabel')}</FormLabel> {/* Translation */}
          <Input
            value={amount}
            onChange={(e) => {
                let inputAmount = e.target.value;

                // Enforce two decimal places
                if (inputAmount.includes('.')) {
                    const [integerPart, decimalPart] = inputAmount.split('.');
                    if (decimalPart.length > 2) {
                        inputAmount = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                }

                // Convert to a float and limit the range
                const amountFloat = parseFloat(inputAmount) || 0;
                if (
                  amountFloat >= 0 &&
                  (
                      (walletType === 'Cash Wallet' && amountFloat <= cashWallet['balance']) ||
                      (walletType === 'Direct Referral Wallet' && amountFloat <= directReferralWallet['balance'])
                  )
                ) {
                    setAmount(parseFloat(inputAmount));
                }
            }}
            type="number"
            placeholder={t('withdraw.amountLabel')}
            min="10"
          />
          {error && <Typography color="danger" sx={{ mt: 1 }}>{error}</Typography>}
        </FormControl>

        <Typography style={{textAlign: 'left', fontSize: '0.8rem', color: 'grey'}}>
          {t('withdraw.withdrawMessage', { 
              balance: walletType === 'Cash Wallet' 
                  ? cashWallet['balance'] 
                  : walletType === 'Direct Referral Wallet' 
                      ? directReferralWallet['balance'] 
                      : 0 
          })} {/* Translation with variable */}
        </Typography>

        {/* Payment Method Selection */}
        <FormControl sx={{ my: 2 }}>
          <FormLabel>{t('withdraw.networkLabel')}</FormLabel> {/* Translation */}
          <RadioGroup
            row
            name="payment-method"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <Box component="label" display="flex" alignItems="center" sx={{ mr: 3, cursor: 'pointer' }}>
              <Radio value="TRC-20" />
              <Typography sx={{ ml: 1 }}>{t('withdraw.trc20')}</Typography> {/* Translation */}
            </Box>
            <Box component="label" display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
              <Radio value="BEP-20" />
              <Typography sx={{ ml: 1 }}>{t('withdraw.bep20')}</Typography> {/* Translation */}
            </Box>
          </RadioGroup>
        </FormControl>

        {/* Wallet Address Input */}
        <FormControl sx={{ my: 2 }}>
          <FormLabel>{t('withdraw.walletAddressLabel')}</FormLabel> {/* Translation */}
          <Input
            value={walletAddress}
            onChange={handleWalletAddressChange}
            type="text"
            placeholder={t('withdraw.walletAddressPlaceholder')}
          />
        </FormControl>

        {/* Confirm Button */}
        <Button 
          variant="solid" 
          color="primary" 
          onClick={handleWithdraw} 
          className="withdraw-button"
          disabled={
            amount < 10 || 
            !walletAddress || 
            (
                (walletType === 'Cash Wallet' && amount > cashWallet['balance']) ||
                (walletType === 'Direct Referral Wallet' && amount > directReferralWallet['balance'])
            )
          } // Only disable if less than $10 or no address
        >
          {t('withdraw.confirm')} {/* Translation */}
        </Button>
      </Card>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
}

export default Withdraw;