import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Pagination, InputAdornment
} from '@mui/material';
import { Search } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminTransactionManagement = () => {
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [transactionsPerPage] = useState(10); // Number of transactions per page
    const [totalPages, setTotalPages] = useState(1); // Total number of pages
    const [totalCount, setTotalCount] = useState(0); // Total transactions count
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch transactions data with pagination and search term whenever page or search term changes
    useEffect(() => {
        if (!localStorage.getItem('adminToken')) {
            navigate("/admin/login");
        } else {
            fetchTransactions(currentPage, searchTerm);
        }
    }, [currentPage, searchTerm]);

    const fetchTransactions = async (page, search = '') => {
        try {
            const response = await axios.get('/api/admin/transaction/list', {
                headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` },
                params: {
                    page: page,
                    limit: transactionsPerPage,
                    search: search
                }
            });

            setTransactions(response.data.data); // Set transactions for the current page
            setTotalCount(response.data.pagination.totalCount); // Update total transactions count
            setTotalPages(response.data.pagination.totalPages); // Set total pages
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value); // Update the current page
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value); // Update search term
        setCurrentPage(1); // Reset to first page when searching
    };

    return (
        <Box sx={{ padding: '20px', margin: 'auto' }}>
            <Typography variant="h4" fontWeight="bold" mb={3} textAlign="center">
                Transaction Management
            </Typography>

            {/* Search Bar */}
            <TextField
                variant="outlined"
                fullWidth
                placeholder="Search by username, description, or type"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
                sx={{ marginBottom: '20px' }}
            />

            <TableContainer component={Paper}>
                <Table aria-label="transaction management table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>ID</strong></TableCell>
                            <TableCell><strong>User ID</strong></TableCell>
                            <TableCell><strong>Username</strong></TableCell>
                            <TableCell><strong>Type</strong></TableCell>
                            <TableCell><strong>Description</strong></TableCell>
                            <TableCell><strong>Amount</strong></TableCell>
                            <TableCell><strong>Status</strong></TableCell>
                            <TableCell><strong>Date</strong></TableCell>
                            <TableCell><strong>Remarks</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.length > 0 ? transactions.map((transaction) => (
                            <TableRow key={transaction.id}>
                                <TableCell>{transaction.id}</TableCell>
                                <TableCell>{transaction.user_id}</TableCell>
                                <TableCell>{transaction.username}</TableCell>
                                <TableCell>{transaction.transaction_type}</TableCell>
                                <TableCell>{transaction.description}</TableCell>
                                <TableCell>{transaction.transaction_type === 'Deposit' || transaction.transaction_type === 'Interest' || transaction.transaction_type === 'Receive' || transaction.transaction_type === 'Refund' ? '+' : '-'}${transaction.amount}</TableCell>
                                <TableCell>{transaction.status}</TableCell>
                                <TableCell>{new Date(transaction.transaction_date).toLocaleString()}</TableCell>
                                <TableCell>{transaction.remarks}</TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                                <TableCell colSpan={9} align="center">
                                    No transactions found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            <Box mt={2} display="flex" justifyContent="center">
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                />
            </Box>
        </Box>
    );
};

export default AdminTransactionManagement;