import React, { useState, useEffect } from 'react';
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography, Button } from '@mui/material';
import { ArrowCircleRight } from '@mui/icons-material';
import { Snackbar } from '@mui/material';
import SendMoneyImage from '../assets/images/infographic-send.png';
import { blue } from '@mui/material/colors';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from '../shared/Loading';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Transfer = () => {
    const { t } = useTranslation(); // Initialize translation
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [user, setUser] = useState(null);
    const [cashWallet, setCashWallet] = useState(null);
    const [interestWallet, setInterestWallet] = useState(null);
    const [investWallet, setInvestWallet] = useState(null);
    const [amount, setAmount] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(''); 
    const navigate = useNavigate();

    // Filter contacts based on search term
    const filteredContacts = contacts.filter(contact => 
        contact.first_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        contact.last_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        contact.phone_number.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const fetchUser = axios.get('/api/user', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserFriendList = axios.get('/api/user/friends', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserWallets = axios.get('/api/user/wallets', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        Promise.all([fetchUser, fetchUserFriendList, fetchUserWallets])
          .then(([userResponse, userFriendsResponse, walletsResponse]) => {
            setUser(userResponse.data);
            setContacts(userFriendsResponse.data);
            walletsResponse.data.map(wallet => {
                if (wallet['wallet_type'] === "Cash Wallet") {
                    setCashWallet(wallet);
                } else if (wallet['wallet_type'] === "Interest Wallet") {
                    setInterestWallet(wallet);
                } else if (wallet['wallet_type'] === "Invest Wallet") {
                    setInvestWallet(wallet);
                }
            });
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }).catch((error) => {
            console.error('Error fetching data:', error);
            navigate("/");
            setIsLoading(false);
        });
    }, []);

    // Handle selecting a contact to proceed with
    const handleSelectContact = (contact) => {
        setSelectedContact(contact);
        setAmount(''); // Reset amount on new contact selection
    };

    // Handle going back to contact list
    const handleBack = () => {
        setSelectedContact(null);
    };

    // Handle submitting the amount
    const handleSubmitAmount = async () => {
        setIsLoading(true);
        
        if (amount > 0) {
            const requestBody = {
                targetUserId: selectedContact['id'],
                amount
            };

            const response = await axios.post('/api/user/transfer', 
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (response.status === 200) {
                navigate('/success', { state: { message: t('transfer.successMessage', { amount, name: `${selectedContact.first_name} ${selectedContact.last_name}` }) } });
                setIsLoading(false);
            } else {
                setSnackbarMessage(t('transfer.transferFailed'));
                setSnackbarOpen(true);
                setIsLoading(false);
            }
        } else {
            setSnackbarMessage(t('transfer.invalidAmount'));
            setSnackbarOpen(true);
            setIsLoading(false);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Box className="send-money-page">

            {/* Back Button */}
            <Box display="flex" justifyContent="flex-start" mt={3} ml={2}>
                <Button
                variant="contained"
                onClick={() => navigate(-1)} // Go back to the previous page
                sx={{
                    background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
                    color: '#ffffff',
                    '&:hover': {
                    background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
                    opacity: 0.9,
                    },
                }}
                >
                {t('group.back')}
                </Button>
            </Box>

            <img src={SendMoneyImage} alt="Send Money" className="header-image" />
            <Typography variant="h5" className="send-money-title">{t('transfer.title')}</Typography>
            <Typography className="send-money-description">
                {t('transfer.description')}
            </Typography>

            {!selectedContact ? (
                <>
                    {/* Search Field */}
                    <TextField
                        label={t('transfer.contactLabel')}
                        placeholder={t('transfer.searchPlaceholder')}
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        margin="normal"
                        className="search-bar"
                        InputProps={{
                            style: {
                                borderRadius: 12,
                                backgroundColor: '#ffffff'
                            }
                        }}
                    />

                    {/* Contact List */}
                    <List>
                        {filteredContacts.map(contact => (
                            <ListItem key={contact.id} className="contact-item">
                                <ListItemAvatar>
                                    <Avatar 
                                        alt={`${contact.first_name || ''} ${contact.last_name || ''}`.trim()} 
                                        src={require(`../assets/images/avatar/${contact?.profile_picture_id}.png`)} 
                                    />
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={`${contact.first_name || ''} ${contact.last_name || ''}`.trim()}
                                    secondary={contact.phone_number} 
                                    primaryTypographyProps={{ className: 'contact-name' }} 
                                    secondaryTypographyProps={{ className: 'contact-phone' }}
                                />
                                <IconButton edge="end" aria-label="transfer" className="transfer-button" onClick={() => handleSelectContact(contact)}>
                                    <ArrowCircleRight style={{ color: blue[800] }} />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            ) : (
                <Box className="amount-section">
                    <Typography variant="h6" className="send-money-title">{t('transfer.sendTo', { name: `${selectedContact.first_name} ${selectedContact.last_name}` })}</Typography>
                    <Typography className="contact-phone">{selectedContact.phone_number}</Typography>

                    {/* Amount Input */}
                    <TextField
                        label={t('transfer.amountLabel')}
                        placeholder={t('transfer.amountPlaceholder')}
                        variant="outlined"
                        value={amount}
                        onChange={(e) => {
                            let inputAmount = e.target.value;

                            // Enforce two decimal places
                            if (inputAmount.includes('.')) {
                                const [integerPart, decimalPart] = inputAmount.split('.');
                                if (decimalPart.length > 2) {
                                    inputAmount = `${integerPart}.${decimalPart.slice(0, 2)}`;
                                }
                            }

                            // Convert to a float and limit the range
                            const amountFloat = parseFloat(inputAmount) || 0;
                            if (amountFloat >= 0 && amountFloat <= cashWallet['balance']) {
                                setAmount(inputAmount);
                            }
                        }}
                        fullWidth
                        margin="normal"
                        className="amount-input"
                        type="number"
                        InputProps={{
                            style: {
                                borderRadius: 12,
                                backgroundColor: '#ffffff'
                            }
                        }}
                        inputProps={{
                            min: 0,
                            max: cashWallet['balance'],
                            step: "0.01"
                        }}
                    />

                    <Typography style={{ textAlign: 'left', fontSize: '0.8rem', color: 'grey' }}>
                        {t('transfer.balanceMessage', { balance: cashWallet['balance'] })}
                    </Typography>

                    {/* Submit Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmitAmount}
                        className="submit-button"
                    >
                        {t('transfer.sendMoney')}
                    </Button>

                    {/* Back Button */}
                    <Button 
                        variant="text" 
                        color="primary" 
                        onClick={handleBack} 
                        className="back-button"
                    >
                        {t('transfer.discard')}
                    </Button>
                </Box>
            )}

            {/* Snackbar for error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default Transfer;
