import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import { Box, Button, Typography, Snackbar, FormControl, Select, MenuItem } from '@mui/material';
import logo from '../assets/images/logo.png';
import logoCn from '../assets/images/logo_cn.png'; // Import Chinese logo
import OtpInput from 'react-otp-input';
import { useNavigate, useSearchParams  } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  margin: 'auto',
  padding: '25px',
  height: '70vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

function Otp() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const [otp, setOtp] = useState(''); 
  const [timer, setTimer] = useState(60); 
  const [canResend, setCanResend] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); 
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate(); 
  const apiCalled = useRef(false);

  const { t, i18n } = useTranslation(); // Initialize useTranslation for i18n

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value); 
  };

  const generateOtp = async () => {
    const token = localStorage.getItem('token'); 

    try {
      await axios.post('/api/auth/otp', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(t('otp.otpRequested')); // Use translation for console log
    } catch (error) {
      console.error('Error generating OTP:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    if (!apiCalled.current) {
      generateOtp();
      apiCalled.current = true;
    }

    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post('/api/auth/verify-otp', { otp }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        console.log(t('otp.otpVerified')); 
        navigate('/home');
      }
    } catch (error) {
      console.error(t('otp.otpVerificationError'), error.response?.data || error.message);
      setSnackbarMessage(error.response?.data.message || t('otp.otpVerificationError'));
      setSnackbarOpen(true);
    }
  };

  const handleResendOtp = () => {
    generateOtp(); 
    setOtp(''); 
    setTimer(60);
    setCanResend(false); 

    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const logoToDisplay = i18n.language === 'chi' || i18n.language === 'zho' ? logoCn : logo;

  return (
    <StyledBox>
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" sx={{ flexGrow: 1 }}>
        <Grid container  sx={{ marginBottom: 5 }}>
          <Grid xs={5}>
            <FormControl>
              <Select
                label={t('language')} 
                defaultValue="eng"
                onChange={handleLanguageChange} 
                sx={{ color: 'primary.main' }}
              >
                  <MenuItem value="eng">{t('languages.english')}</MenuItem>
                  <MenuItem value="chi">{t('languages.chinese')}</MenuItem>
                  <MenuItem value="ind">{t('languages.indonesian')}</MenuItem>
                  <MenuItem value="kor">{t('languages.korean')}</MenuItem>
                  <MenuItem value="hin">{t('languages.hindi')}</MenuItem>
                  <MenuItem value="jpn">{t('languages.japanese')}</MenuItem>
                  <MenuItem value="th">{t('languages.thai')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <img 
            src={logoToDisplay} 
            alt="Logo" 
            style={{ maxWidth: '80%', height: 'auto', marginBottom: '20px' }} 
          />
        </Grid>
        
        
        <Grid 
          container 
          xs={12} 
          justifyContent="center" 
          alignItems="center" 
          direction="column"
          style={{ textAlign: 'center' }} // Centering the text
        >
          <Typography xs={{ mb: 3 }} variant="p">
            {t('otp.enterOtp', { email })}
          </Typography>
          <OtpInput 
            value={otp}
            onChange={setOtp}
            numInputs={6} 
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: '30px', 
              height: '30px', 
              margin: '0 5px', 
              textAlign: 'center', 
              fontSize: '20px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        </Grid>

        <Grid xs={12} sx={{ marginTop: 2 }}>
          <Typography variant="body2">
            {canResend ? (
              <Button 
                sx={{ 
                  background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                  color: 'white',
                  '&:hover': {
                    opacity: 0.9,
                    background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                  },
                }}   
                variant="contained" onClick={handleResendOtp}>
                {t('otp.resendOtp')} {/* Translated Resend OTP button */}
              </Button>
            ) : (
              <span>{t('otp.resendOtpIn')} {timer} {t('messages.resendOtp')}</span> 
            )}
          </Typography>
        </Grid>

        <Grid xs={12} sx={{ marginTop: 3 }}>
          <Button
            sx={{ 
              background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
              color: 'white',
              '&:hover': {
                opacity: 0.9,
                background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
              },
            }}  
            variant="contained" onClick={handleSubmit}>
            {t('otp.verifyOtp')} {/* Translated Verify OTP button */}
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </StyledBox>
  );
}

export default Otp;
