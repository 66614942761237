import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBackIos } from '@mui/icons-material';
import { Alert, Box, Button, FormControl, FormLabel, Input, Link, MenuItem, Select, Snackbar, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import axios from 'axios'; // Import axios for making API calls
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate and useLocation
import * as yup from 'yup';
import loginBg from '../assets/images/login/loginBg.jpg'; // Adjust the path as needed
import logo from '../assets/images/logo.png'; // Adjust the path as necessary
import logoCn from '../assets/images/logo_cn.png'; // Import Chinese logo

// Define the validation schema using Yup
const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    username: yup.string().required('Username is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    country: yup.string().required('Country is required'),
    referralCode: yup.string().optional(),
});

function SignUp() {
    const { t, i18n  } = useTranslation();
    const [language, setLanguage] = useState(i18n.language); // State to hold the selected language
    const navigate = useNavigate(); // Initialize useNavigate
    const location = useLocation(); // Initialize useLocation
    const queryParams = new URLSearchParams(location.search);
    const referralFromUrl = queryParams.get('referral'); // Extract referral code from URL

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const [apiError, setApiError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
    const [countries, setCountries] = useState([]); // State to hold the list of countries

    const goBack = () => {
        navigate(-1); // Function to go back to the previous page
    };

    // Autofill referral code when component mounts
    useEffect(() => {
        if (referralFromUrl) {
            setValue('referralCode', referralFromUrl); // Set referral code in form state
        }
    }, [referralFromUrl, setValue]);

    useEffect(() => {
        const fetchCountries = async () => {
            console.log("get country")
            try {
                const response = await axios.get('/api/user/countries');
                setCountries(response.data); // Set the countries list from API response
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries(); // Call the function when the component mounts
    }, []);

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage); // Update language state
        i18n.changeLanguage(selectedLanguage); // Change language
    };

    const onSubmit = async (data) => {
        console.log(data); // Handle your API submission here
        console.log(language);

        try {
            setApiError(''); // Clear any previous API errors
            const response = await axios.post('/api/auth/register', {
                ...data,
                language // Include language in the registration data
            });            console.log('Registration successful:', response.data);

            localStorage.setItem('token', response.data.token);

            navigate(`/verify-otp?email=${data.email}`);
        } catch (error) {
            console.error('Error during registration:', error.response?.data || error.message);
            setApiError(error.response?.data?.message || 'An error occurred. Please try again.');
            setOpenSnackbar(true); // Open the Snackbar to show the error

        }
    };

    // Handle Snackbar close
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };

    const logoToDisplay = i18n.language === 'chi' || i18n.language === 'zho' ? logoCn : logo;

    return (
        <div className='signUp'>
            <Box 
                sx={{ 
                    p: 3,
                    backgroundImage: `url(${loginBg})`, // Set the background image
                    backgroundSize: 'cover', // Cover the entire area
                    backgroundPosition: 'center', // Center the image
                    backgroundRepeat: 'no-repeat', // Prevent repeating the image
                }}>
                {/* Header with Back Button and Logo */}
                <Grid container justifyContent={"space-between"} alignItems='center' sx={{ marginBottom: 5 }}>
                    <Grid xs={5}>
                        <Button startIcon={<ArrowBackIos />} onClick={goBack} />
                    </Grid>
                    <Grid xs={5}>
                        <FormControl>
                            <Select
                                label={t('language')}
                                defaultValue="eng" // Default language value (should match the MenuItem values)
                                onChange={handleLanguageChange} // Attach change handler
                                sx={{ color: 'primary.main' }}
                                value={language}
                            >
                                <MenuItem value="eng">{t('languages.english')}</MenuItem>
                                <MenuItem value="chi">{t('languages.chinese')}</MenuItem>
                                <MenuItem value="ind">{t('languages.indonesian')}</MenuItem>
                                <MenuItem value="kor">{t('languages.korean')}</MenuItem>
                                <MenuItem value="hin">{t('languages.hindi')}</MenuItem>
                                <MenuItem value="jpn">{t('languages.japanese')}</MenuItem>
                                <MenuItem value="th">{t('languages.thai')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Logo */}
                <img src={logoToDisplay} alt="Logo" style={{ height: '60px', objectFit: 'contain', marginBottom: 20 }} />

                <Grid sx={{ textAlign: 'left' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            mb: 2,
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel className="formControlLabel">{t('form.firstName')}</FormLabel>
                                <Input {...register('firstName')} placeholder={t('form.firstNamePlaceholder')} />
                                <Typography color="error">{errors.firstName?.message}</Typography>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            mb: 2,
                        }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel className="formControlLabel">{t('form.lastName')}</FormLabel>
                                <Input {...register('lastName')} placeholder={t('form.lastNamePlaceholder')} />
                                <Typography color="error">{errors.lastName?.message}</Typography>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            mb: 2,
                        }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel className="formControlLabel">{t('form.username')}</FormLabel>
                                <Input {...register('username')} placeholder={t('form.usernamePlaceholder')} />
                                <Typography color="error">{errors.username?.message}</Typography>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            mb: 2,
                        }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel className="formControlLabel">{t('form.email')}</FormLabel>
                                <Input {...register('email')} placeholder={t('form.emailPlaceholder')} />
                                <Typography color="error">{errors.email?.message}</Typography>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            mb: 2,
                        }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel className="formControlLabel">{t('form.phoneNumber')}</FormLabel>
                                <Input {...register('phoneNumber')} placeholder={t('form.phoneNumberPlaceholder')} />
                                <Typography color="error">{errors.phoneNumber?.message}</Typography>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            mb: 2,
                        }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel className="formControlLabel">{t('form.password')}</FormLabel>
                                <Input type="password" {...register('password')} placeholder={t('form.passwordPlaceholder')} />
                                <Typography color="error">{errors.password?.message}</Typography>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            mb: 2,
                        }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel className="formControlLabel">{t('form.confirmPassword')}</FormLabel>
                                <Input type="password" {...register('confirmPassword')} placeholder={t('form.confirmPasswordPlaceholder')} />
                                <Typography color="error">{errors.confirmPassword?.message}</Typography>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            mb: 2,
                        }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel className="formControlLabel">{t('form.country')}</FormLabel>
                                <Select {...register('country')} displayEmpty>
                                    <MenuItem value="">
                                        <em>{t('form.countryPlaceholder')}</em>
                                    </MenuItem>
                                    {countries.map((country) => (
                                        <MenuItem key={country.iso} value={country.iso}>
                                            {t(`countries.${country.iso}`)} 
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Typography color="error">{errors.country?.message}</Typography>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            mb: 2,
                        }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel className="formControlLabel">{t('form.referralCode')}</FormLabel>
                                <Input {...register('referralCode')} placeholder={t('form.referralCodePlaceholder')} />
                            </FormControl>
                        </Box>

                        <Button type="submit" variant="contained" color="primary" fullWidth>{t('form.signUp')}</Button>
                    </form>
                    <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
                        {t('form.alreadyHaveAccount')} <Link href="/login">{t('form.login')}</Link>
                    </Typography>
                </Grid>

                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        {apiError}
                    </Alert>
                </Snackbar>
            </Box>
        </div>
    );
}

export default SignUp;
