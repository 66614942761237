import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import { Box, Button } from '@mui/material';
import logo from '../assets/images/logo.png';  
import logoCn from '../assets/images/logo_cn.png'; // Import Chinese logo
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import splashBg from '../assets/images/splash/splashBg.jpg'; 
import { useTranslation } from 'react-i18next'; // Import useTranslation

function Splash() {
  const { t, i18n } = useTranslation(); // Initialize useTranslation
  const navigate = useNavigate(); 

  const verifyToken = async () => {
    const token = localStorage.getItem('token');  
    if (!token) {
      return { valid: false, message: t('splash.noTokenFound') }; // Use translation
    }

    try {
      const response = await axios.post(
        '/api/auth/verify-token',
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`  
          }
        }
      );
      return { valid: true, message: t('splash.tokenValid') }; // Use translation
    } catch (error) {
      console.error('Token verification failed:', error);
      return { valid: false, message: t('splash.invalidToken') }; // Use translation
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      const tokenVerification = await verifyToken();
      if (tokenVerification.valid) {
        console.log('Token verified:', tokenVerification.data);
        navigate('/home');
      } 
    };
    checkToken();
  }, [navigate]);

  const logoToDisplay = i18n.language === 'chi' || i18n.language === 'zho' ? logoCn : logo;

  return (
    <Box 
      sx={{ 
        textAlign: 'center',
        margin: 'auto',
        height: '100vh', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        backgroundImage: `url(${splashBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <img 
            src={logoToDisplay} 
            alt="Logo" 
            style={{ maxWidth: '80%', height: 'auto', marginBottom: 'auto' }} 
          />
        </Grid>
        <Grid xs={12}>
          <Grid container spacing={2} justifyContent="space-around" alignItems="center">
            <Grid xs={5}>
              <Button
                sx={{ 
                    minHeight: '70px',
                    minWidth:'150px',
                    background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    color: 'white',
                    '&:hover': {
                        opacity: 0.9,
                        background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    },
                    marginTop: 2
                }} 
                variant="contained" 
                onClick={() => navigate('/signup')} fullWidth>
                {t('splash.signUp')} {/* Use translation */}
              </Button>
            </Grid>
            <Grid xs={5}>
              <Button 
                sx={{ 
                    minHeight: '70px',
                    minWidth:'150px',
                    background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    color: 'white',
                    '&:hover': {
                        opacity: 0.9,
                        background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                    },
                    marginTop: 2
                }} 
                variant="contained" 
                onClick={() => navigate('/login')} fullWidth>
                {t('splash.login')} {/* Use translation */}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Splash;
