import React, { useState, useEffect } from 'react';
import { Typography, Button, Card, Radio, RadioGroup, FormControl, FormLabel, Input, IconButton, Box } from '@mui/joy';
import { Snackbar } from '@mui/material';
import QRCode from 'react-qr-code';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FileUpload, ContentCopy } from '@mui/icons-material'; // Import icons
import Loading from '../shared/Loading';
import { useTranslation } from 'react-i18next'; // Import useTranslation

function Deposit() {
  const { t } = useTranslation(); // Initialize translation
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(null); // Minimum amount
  const [paymentMethod, setPaymentMethod] = useState('TRC-20');
  const [walletAddress, setWalletAddress] = useState('TF3ghkEWDJuGbMG8GyLXmhq6C8BWu2G1L2');
  const [receipt, setReceipt] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate("/");
    }

    const fetchUser = axios.get('/api/user', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });

    Promise.all([fetchUser])
      .then(([userResponse]) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }).catch((error) => {
        console.error('Error fetching data:', error);
        navigate("/");
        setIsLoading(false);
      });
  }, []);

  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value, 10);
    if (value < 100) {
      setError(t('deposit.minAmountError'));
    } else if (value > 100000) {
      setError(t('deposit.maxAmountError'));
    } else {
      setError(null); // Clear error if within valid range
    }

    setAmount(value);
  };

  const handleTopUp = async () => {
    if (amount >= 100 && amount <= 100000 && receipt) {
      const receiptBase64 = await fileToBase64(receipt);
      const requestBody = {
        amount,
        paymentMethod,
        receiptImage: receiptBase64
      };
      setError(null); // Clear any previous errors
      setIsLoading(true);

      try {
        const response = await axios.post('/api/user/deposit/request', 
          requestBody,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
        );

        // Check for successful response
        if (response.status === 200) {
          navigate('/success', { state: { message: t('deposit.successMessage') } });
        } else {
          setSnackbarMessage(t('deposit.requestFailed'));
          setSnackbarOpen(true);
        }

        setIsLoading(false);

      } catch (error) {
        setSnackbarMessage(error.response?.data.message || t('deposit.requestFailed'));
        setSnackbarOpen(true);
        setIsLoading(false);
      }
    } else {
      setError(t('deposit.invalidInputError'));
    }
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(walletAddress);
    setSnackbarMessage(t('deposit.copied'));
    setSnackbarOpen(true);
  };

  const handleReceiptUpload = (e) => {
    const file = e.target.files[0];
    setReceipt(file);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result); // This result includes the data URI prefix (e.g., data:image/png;base64,...)
      reader.onerror = error => reject(error);
    });
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="top-up-page">
      <Card variant="outlined" className="top-up-card">

        {/* Back Button */}
        <Box display="flex" justifyContent="start" mt={3} ml={2}>
          <Button
            variant="solid"
            onClick={() => navigate(-1)} // Go back to the previous page
            sx={{
              background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
              color: '#ffffff',
              '&:hover': {
                background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
                opacity: 0.9,
              },
            }}
          >
            {t('group.back')}
          </Button>
        </Box>
        
        <Typography level="h4" className="top-up-title">{t('deposit.title')}</Typography>
        <Typography level="body1" className="top-up-description">
          {t('deposit.description')}
        </Typography>
        
        {/* Amount Input */}
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>{t('deposit.amountLabel')}</FormLabel>
          <Input
            value={amount}
            onChange={handleAmountChange}
            type="number"
            placeholder={t('deposit.amountPlaceholder')}
            min="100"
            max="100000"
          />
          {error && <Typography color="danger" sx={{ mt: 1 }}>{error}</Typography>}
        </FormControl>

        <Typography style={{textAlign: 'left', fontSize: '0.8rem', color: 'grey'}}>
          {t('deposit.withdrawMessage')}
        </Typography>

        {/* Payment Method Selection */}
        <FormControl sx={{ my: 2 }}>
          <FormLabel>{t('deposit.networkLabel')}</FormLabel>
          <RadioGroup
            row
            name="payment-method"
            value={paymentMethod}
            onChange={(e) => {
              setPaymentMethod(e.target.value);
              setWalletAddress(e.target.value === 'TRC-20' ? 'TF3ghkEWDJuGbMG8GyLXmhq6C8BWu2G1L2' : '0xEB3de9789f3c04E9473aC01596DfB38f14280533');
            }}
          >
            <Box component="label" display="flex" alignItems="center" sx={{ mr: 3, cursor: 'pointer' }}>
              <Radio value="TRC-20" />
              <Typography sx={{ ml: 1 }}>TRC-20</Typography>
            </Box>
            <Box component="label" display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
              <Radio value="BEP-20" />
              <Typography sx={{ ml: 1 }}>BEP-20</Typography>
            </Box>
          </RadioGroup>
        </FormControl>
        
        {/* QR Code and Wallet Address */}
        <div className="wallet-info">
          <QRCode value={walletAddress} size={128} />
          <div className="wallet-address">
            <Typography 
              level="body1" 
              sx={{ 
                mt: 1, 
                wordBreak: 'break-all', // Allows the address to break within words
                whiteSpace: 'normal',   // Ensures it can wrap if needed
                overflowWrap: 'break-word' // Enables wrapping for long words
              }}
            >
              {walletAddress}
            </Typography>
            <IconButton onClick={handleCopyAddress} color="primary">
              <ContentCopy />
            </IconButton>
          </div>
        </div>

        {/* Upload Payment Receipt */}
        <FormControl sx={{ my: 2 }}>
          <FormLabel>{t('deposit.uploadReceiptLabel')}</FormLabel>
          <Input
            type="file"
            onChange={handleReceiptUpload}
            accept=".pdf, image/*" // Accepts PDF and any image type
            sx={{ display: 'flex', alignItems: 'center' }}
          />
          {receipt && <Typography sx={{ mt: 1 }}>{t('deposit.uploaded')}: {receipt.name}</Typography>}
        </FormControl>

        {/* Confirm Button */}
        <Button 
          variant="solid" 
          color="primary" 
          onClick={handleTopUp} 
          className="top-up-button"
          disabled={amount < 100 || amount > 100000 || !receipt}
        >
          {t('deposit.confirm')}
        </Button>

        <Typography level="body-xs" className="top-up-description">
          {t('deposit.notice')}
        </Typography>
      </Card>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
}

export default Deposit;
