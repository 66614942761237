import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation

// Font Awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faHome, faUserGroup } from '@fortawesome/free-solid-svg-icons';

// Logo imports
import logo from '../assets/images/logo.png';
import logoCn from '../assets/images/logo_cn.png'; // Import Chinese logo

function Header() {
    const { i18n } = useTranslation(); // Initialize translation
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Determine which logo to use based on the current language
    const logoToDisplay = i18n.language === 'chi' || i18n.language === 'zho' ? logoCn : logo;

    return (
        <header className={`app-header ${isScrolled ? 'scrolled' : ''}`}>
            {/* Sidebar Toggle Button */}
            <button className="header-btn toggle-btn" onClick={() => navigate('/home')} aria-label="Open Sidebar">
                <FontAwesomeIcon icon={faHome} size="xs" />
            </button>

            {/* Logo in the Center */}
            <div className="header-logo" onClick={() => navigate('/home')}>
                <img src={logoToDisplay} alt="Logo" className="logo-img" />
            </div>

            {/* Notifications Button */}
            <button className="header-btn notification-btn" onClick={() => window.location.href = '/group'} aria-label="Group">
                <FontAwesomeIcon icon={faUserGroup} size="xs" />
            </button>
        </header>
    );
}

export default Header;
