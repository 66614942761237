import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { CheckCircle, Cancel, Delete, Settings, Image } from '@mui/icons-material';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminPendingWithdrawals = () => {
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState([]);
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('adminToken')) {
            navigate("/admin/login");
        }

        // Fetch transaction data when component mounts
        fetchTransactions();
    }, []);

    const fetchTransactions = async () => {
        try {
            const response = await axios.get('/api/admin/transaction/list/withdrawals', {
                headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
            });
            console.log(response.data);
            setTransactions(response.data);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    const handleApprove = (transaction) => {
        setSelectedTransaction(transaction);
        setOpenApproveModal(true);
    };

    const confirmApprove = async () => {
        try {

            console.log(selectedTransaction['id'])

            const requestBody = {
                withdrawal_id: selectedTransaction['id'],
                action: "approve"
            };
            
            await axios.post(`/api/admin/transaction/review/withdrawal`, requestBody, {
                headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
            });

            setSnackbarMessage(`Transaction with ID: ${selectedTransaction["id"]} approved`);
            setSnackbarOpen(true);
            fetchTransactions();
            // Additional logic, such as refreshing the list or updating the UI
        } catch (error) {
            console.error('Error approving Transaction:', error);
        } finally {
            setOpenApproveModal(false);
            setSelectedTransaction(null);
        }
    };
    
    const handleReject = (transaction) => {
        setSelectedTransaction(transaction);
        setOpenRejectModal(true);
    };
    
    const confirmReject = async () => {
        try {

            console.log(selectedTransaction['id'])

            const requestBody = {
                withdrawal_id: selectedTransaction['id'],
                action: "reject"
            };
            
            await axios.post(`/api/admin/transaction/review/withdrawal`, requestBody, {
                headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
            });

            setSnackbarMessage(`Transaction with ID: ${selectedTransaction["id"]} rejected`);
            setSnackbarOpen(true);
            fetchTransactions();
            // Additional logic, such as refreshing the list or updating the UI
        } catch (error) {
            console.error('Error rejecting Transaction:', error);
        } finally {
            setOpenRejectModal(false);
            setSelectedTransaction(null);
        }
    };

    const handleViewImage = (transaction) => {
        // Implement the image viewing logic here, e.g., open a modal
        console.log(`Viewing image for user with ID: ${transaction}`);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ padding: '20px', margin: 'auto' }}>
            <Typography variant="h4" fontWeight="bold" mb={3} textAlign="center">
                Pending Withdrawals
            </Typography>
            
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="transaction management table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>ID</strong></TableCell>
                            <TableCell><strong>User ID</strong></TableCell>
                            <TableCell><strong>Username</strong></TableCell>
                            <TableCell><strong>Withdrawal Method</strong></TableCell>
                            <TableCell><strong>Wallet Type</strong></TableCell>
                            <TableCell><strong>Wallet Address</strong></TableCell>
                            <TableCell><strong>Amount</strong></TableCell>
                            <TableCell><strong>Actual Amount</strong></TableCell>
                            <TableCell><strong>Status</strong></TableCell>
                            <TableCell><strong>Date</strong></TableCell>
                            <TableCell align="center"><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.length > 0 ? transactions.map((transaction) => (
                            <TableRow key={transaction.id}>
                                <TableCell>{transaction.id}</TableCell>
                                <TableCell>{transaction.user_id}</TableCell>
                                <TableCell>{transaction.username}</TableCell>
                                <TableCell>{transaction.withdrawal_method}</TableCell>
                                <TableCell>{transaction.wallet_type}</TableCell>
                                <TableCell>{transaction.wallet_address}</TableCell>
                                <TableCell>${transaction.withdrawal_amount}</TableCell>
                                <TableCell>${(transaction.withdrawal_amount * 0.95).toFixed(2)}</TableCell>
                                <TableCell>{transaction.status}</TableCell>
                                <TableCell>{new Date(transaction.withdrawal_date).toLocaleString()}</TableCell>
                                <TableCell align="center">
                                    {/* Approve Button */}
                                    <IconButton 
                                        color="success" 
                                        title="Approve"
                                        onClick={() => handleApprove(transaction)}
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                    
                                    {/* Reject Button */}
                                    <IconButton 
                                        color="error" 
                                        title="Reject"
                                        onClick={() => handleReject(transaction)}
                                    >
                                        <Cancel />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                                <TableCell colSpan={10} align="center">
                                    No transactions found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={openApproveModal}
                onClose={() => setOpenApproveModal(false)}
            >
                <DialogTitle>Confirm Approval</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to complete this transaction?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenApproveModal(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={confirmApprove} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openRejectModal}
                onClose={() => setOpenRejectModal(false)}
            >
                <DialogTitle>Confirm Reject</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to reject this transaction?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRejectModal(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={confirmReject} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default AdminPendingWithdrawals;